import { Guid } from 'guid-typescript';
import { ProxyBase } from "./common";
import { NewPrivatePersonContractInitialData, LegalPersonContractData, Point, PrivatePersonContractData, UserContractDataBase, FileDescription } from "./dataContracts";

export class ManagementObjectsPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ManagementObjectsPresentation/", token, tokenType)
    }

    public async getPrivatePersonContracts(): Promise<PrivatePersonContractData[]> {
        try {
            return await this.getDataFromAction<PrivatePersonContractData[]>("GetPrivatePersonContracts");
        } catch (error) {
            console.debug(error);
            throw error
        }
    }

    public async getLegalPersonContracts(): Promise<LegalPersonContractData[]> {
        try {
            return await this.getDataFromAction<LegalPersonContractData[]>("GetLegalPersonContracts");
        } catch (error) {
            console.debug(error);
            throw error
        }
    }

    public async getLegalPersonContractFilesInfo(orderId: string): Promise<FileDescription[]> {
        try {
            return await this.getDataFromAction<FileDescription[]>("GetLegalPersonContractFilesInfo", {
                orderId: orderId
            });
        } catch (error) {
            console.debug(error);
            throw error
        }
    }
    public async getContractsForFilters(buildingId?: Guid): Promise<UserContractDataBase[]> {
        const params: Record<string, string> = {}

        if (buildingId) {
            params.buildingId = buildingId.toString();
        }

        return this.getDataFromAction<UserContractDataBase[]>("GetContractsForFilters", params);
    }

    public async getPoints(): Promise<Point[]> {
        return await this.getDataFromAction("GetPoints");
    }

    public getPrivatePersonContractInitialData(): Promise<NewPrivatePersonContractInitialData> {
        return this.getDataFromAction<NewPrivatePersonContractInitialData>("GetPrivatePersonContractInitialData")
    }

    public getLegalPersonContractFile(orderId: string, fileId: number): Promise<Blob> {
        return this.downloadData("GetLegalPersonContractFile", {
            orderId: orderId,
            fileId: fileId.toString()
        })
    }
}