import { ref } from "vue";
import { endOfMonth, startOfMonth, subMonths, max } from 'date-fns';
export class DateHelper {

    public static getDate(date: MonthAndYear): Date {
        return new Date(date.year, date.month, 1);
    }

    public static dateRangeSelectorFormat = (date: [Date, Date]) => {
        const start = DateHelper.dateFormatter(date[0]);
        const end = DateHelper.dateFormatter(date[1]);
        return `${start}...${end}`;
    }

    public static dateRangeSelectorPresetRanges = (minDate: Date) => ref([
        { label: 'Сегодня', range: [new Date(), new Date()] },
        { label: 'Текущий месяц', range: [max([startOfMonth(new Date()), minDate]), endOfMonth(new Date())] },
        { label: 'Прошлый месяц', range: [max([startOfMonth(subMonths(new Date(), 1)), minDate]), endOfMonth(subMonths(new Date(), 1))], },
        { label: 'Tри месяца', range: [max([startOfMonth(subMonths(new Date(), 3)), minDate]), endOfMonth(new Date())] },
    ]);

    private static dateFormatter = (date: Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`
    }

    public static getMonthRangeDates(beginCheckDate: Date, endCheckDate: Date): [Date, Date] | undefined {
        const firstDate = beginCheckDate.getDate();
        const secondDate = endCheckDate.getDate();

        let firstMonth = beginCheckDate.getMonth();
        let secondMonth = endCheckDate.getMonth();

        const beginYear = beginCheckDate.getFullYear();
        const endYear = endCheckDate.getFullYear();

        const daysSecondMonth = DateHelper.getDaysInMonth(endYear, secondMonth);

        if (firstDate > 1) {
            firstMonth++;
        }

        if (secondDate === daysSecondMonth) {
            secondMonth++;
        }

        if (firstMonth >= secondMonth) return;

        const beginDate = new Date(beginYear, firstMonth, 1);
        const endDate = new Date(endYear, secondMonth, 0); // последний день предыдущего месяца

        return [beginDate, endDate];
    }

    public static getDaysInMonth(year: number, month: number) {
        return new Date(year, month + 1, 0).getDate();
    }
}

export type MonthAndYear = {
    month: number,
    year: number
}

