import { ProxyBase } from "./common";
import { Guid } from "guid-typescript";
import { getFormattedBeginDate, getFormattedEndDate } from "@/common/dateHelper";
import { EnergyDirection, EnergyType, MeterDeviceEnergyForPowerSet, MeterDeviceEnergySet, MeterDeviceIndicationSet, MeterDevicePowerSet } from "./dataContracts";

export class MeterDeviceDataPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ImsMeterDeviceDataPresenter/", token, tokenType)
    }

    public async getMeterDeviceIndications(pointId: Guid, energyType: EnergyType, direction: EnergyDirection, beginDate: Date, endDate: Date): Promise<MeterDeviceIndicationSet> {

        return await this.getDataFromAction<MeterDeviceIndicationSet>("GetMeterDeviceIndications", {
            pointId: pointId.toString(),
            energyType: energyType.toString(),
            direction: direction.toString(),
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        })
    }

    public async getMeterDevicePowers(pointId: Guid, beginDate: Date, endDate: Date): Promise<MeterDevicePowerSet> {

        return await this.getDataFromAction<MeterDevicePowerSet>("GetMeterDevicePowers", {
            pointId: pointId.toString(),
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        })
    }

    public async getMeterDeviceEnergy(pointId: Guid, energyType: EnergyType, direction: EnergyDirection, beginDate: Date, endDate: Date,
        size?: number, page?: number): Promise<MeterDeviceEnergySet> {

        const params: Record<string, string> = {
            pointId: pointId.toString(),
            energyType: energyType.toString(),
            direction: direction.toString(),
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        };

        if (size && page && size > 0 && page > 0) {
            params.size = size.toString();
            params.page = page.toString();
        }

        return await this.getDataFromAction<MeterDeviceEnergySet>("GetMeterDeviceEnergy", params)
    }

    public async getMeterDeviceEnergyForPower(pointId: Guid, date: Date): Promise<MeterDeviceEnergyForPowerSet> {
        return await this.getDataFromAction<MeterDeviceEnergyForPowerSet>("GetMeterDeviceEnergyForPower", {
            pointId: pointId.toString(),
            firstDayOfMonth: getFormattedBeginDate(date),
        })
    }
}