import { ProxyBase } from "./common";
import { FilesConfiguration } from "@/common/contractsFilesConfiguration";

export class ConfigurationPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ConfigurationPresenter/", token, tokenType)
    }

    public async getContractsFilesConfiguration(): Promise<FilesConfiguration> {
        return await this.getDataFromAction<FilesConfiguration>("GetContractsFilesConfiguration");
    }
    

    public async getAppealsFilesConfiguration(): Promise<FilesConfiguration> {
        return await this.getDataFromAction<FilesConfiguration>("GetAppealsFilesConfiguration");
    }
    
    public async getTechConnectionFilesConfiguration(): Promise<FilesConfiguration> {
        return await this.getDataFromAction<FilesConfiguration>("GetTechConnectionFilesConfiguration");
    }
}