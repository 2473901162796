import dayjs from "dayjs";
import { MonthAndYear } from "./dateSelectorHelper";

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const intlDateFormat = new Intl.DateTimeFormat('ru')
const intlDateTimeFormat = new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })

export const formatDate = (requestDate: string): string => intlDateFormat.format(new Date(requestDate))
export const formatDateTime = (requestDate: string): string => intlDateTimeFormat.format(new Date(requestDate))

export const getFormattedBeginDate = (date: Date): string => {
    const dateTime = date.setHours(0, 0, 0, 0);
    return dayjs(dateTime).format(dateFormat);
}

export const getFormattedEndDate = (date: Date): string => {
    const dateTime = date.setHours(23, 59, 59, 999);
    return dayjs(dateTime).format(dateFormat);
}

export const startOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const endOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
    "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

export const monthsPrepositional = ["январе", "феврале", "марте", "апреле", "мае", "июне",
    "июле", "августе", "сентябре", "октябре", "ноябре", "декабре"]

export const getMonthAndYear = (date: Date): MonthAndYear => ({ month: date.getMonth(), year: date.getFullYear() })