import { MeterDeviceEnergyForPowerSet } from './../../apiProxies/dataContracts';
import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import ProxyFactory from "@/apiProxies/proxyFactory";
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript"
import { imsModule } from "@/store/modules/ims";
import dayjs from "dayjs";
import { MonthAndYear, DateHelper } from '@/common/dateSelectorHelper';
import { preserveModule } from '../storeObject';

const moduleName = 'imsEnergyForPowerModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsEnergyForPowerModule extends VuexModule {

    private _dateCheck: MonthAndYear = {year: (dayjs()).year(), month : (dayjs()).month()}
    private _loadPointEnergyForPowerData = false;
    private _currentPointEnergyForPower: MeterDeviceEnergyForPowerSet | null = null;

    get loadPointEnergyForPowerData(): boolean {
        return this._loadPointEnergyForPowerData;
    }

    get currentPointEnergyForPowerSet(): MeterDeviceEnergyForPowerSet | null {
        return this._currentPointEnergyForPower;
    }

    get dateCheck(): MonthAndYear {
        return this._dateCheck;
    }

    @Mutation
    setCheckDate(value: MonthAndYear): void {
        this._dateCheck = value;
    }

    @Mutation
    setLoadingPointEnergyForPowerData(value: boolean): void {
        this._loadPointEnergyForPowerData = value;
    }

    @Mutation
    setCurrentPointEnergyForPower(currentPointEnergy: MeterDeviceEnergyForPowerSet | null): void {
        this._currentPointEnergyForPower = currentPointEnergy;
    }

    @Action({ rawError: true })
    async updateCurrentPointEnergyForPowerData(): Promise<void> {
        try {
            this.setLoadingPointEnergyForPowerData(true);
            const proxy = await ProxyFactory.createMeterDeviceDataPresentationProxy();

            if (imsModule.currentPoint) {
                const pointIdAsGuid = Guid.parse(imsModule.currentPoint);

                const meterEnergyForPower = await proxy.getMeterDeviceEnergyForPower(pointIdAsGuid, DateHelper.getDate(this._dateCheck));
                this.setCurrentPointEnergyForPower(meterEnergyForPower);
            }
        }
        catch (exc) {
            this.setCurrentPointEnergyForPower({} as MeterDeviceEnergyForPowerSet);
            Logger.logError('updateCurrentPointEnergyForPowerData FAILED!', exc)
            throw exc;
        }
        finally {
            this.setLoadingPointEnergyForPowerData(false);
        }
    }
}

export const imsEnergyForPowerModule = getModule(ImsEnergyForPowerModule);