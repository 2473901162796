
import { defineComponent } from "vue";
import { authenticationModule } from "../store/modules/authentication";
import { managementObjectsModule as management } from "../store/modules/managementObjects";
import { AppRouteNames, getPath } from "@/router/route-names";
import { techConnectionModule as techConnection } from "@/store/modules/techConnection";
import { ManagementObjectState } from "@/apiProxies/dataContracts";

export default defineComponent({
  name: "OidcCallback",
  mounted() {
    authenticationModule
      .oidcSignInCallback(undefined)
      .then(async () => {
        const legalContractsPromise = management.loadLegalPersonContracts()
        const techConenctionPromise = techConnection.loadTechConnectionRows()
        const personContractsPromise = management.loadPrivatePersonContracts()

        await Promise.all([techConenctionPromise, personContractsPromise, legalContractsPromise]);

        const legalApproved = !!management.legalPersonContracts.find(c => c.state == ManagementObjectState.approved)
        const privateApproved = !!management.privatePersonContracts.find(c => c.state == ManagementObjectState.approved)

        if (legalApproved || privateApproved) {
          return getPath(AppRouteNames.PersonalAccount)
        }

        const techConnections = techConnection.techConnectionRows && techConnection.techConnectionRows.length > 0
        const legalContracts = management.legalPersonContracts && management.legalPersonContracts.length > 0
        const privateContracts = management.privatePersonContracts && management.privatePersonContracts.length > 0

        if (techConnections || legalContracts || privateContracts) {
          return getPath(AppRouteNames.ManagementObject)
        }

        return getPath(AppRouteNames.FirstLogin)
      })
      .then((path) => this.$router.push(path))
      .catch(() => {
        this.$router.push("/signin-oidc-error");
      })
  }
})
