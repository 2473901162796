import { Store } from 'vuex'
import { VuexPersistence } from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'ims'
});

export const store = new Store({
  state: {},
  plugins: [vuexLocal.plugin]
});