import { Building, BuildingsFilterData } from './../../apiProxies/dataContracts';
import { imsEnergyModule } from './imsEnergy';
import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { PointCommonProperties, Point, EnergyDirection, EnergyType, PeriodicData, PeriodDependent, EnergyDependent } from "@/apiProxies/dataContracts";
import ProxyFactory from "@/apiProxies/proxyFactory";
import dayjs, { Dayjs } from "dayjs";
import minMax from 'dayjs/plugin/minMax';
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript";
import { imsModuleIndications } from './imsIndications';
import { imsPowersModule } from './imsPowers';
import { imsEventLogModule } from './imsEventLog';
import { imsEnergyForPowerModule } from './imsEnergyForPower';
import { preserveModule } from '../storeObject';

const moduleName = 'imsModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsModule extends VuexModule {

    private _beginCheckDate: string | null = dayjs().startOf("month").toString();
    private _endCheckDate: string | null = dayjs().endOf("month").toString();

    private _currentPointMinCheckDate: string | null = dayjs().toString();

    private _pointCommonPropertiesCache: { [key: string]: PointCommonProperties } = {};

    private _energyForPower = false;

    private _energyType = EnergyType.Active;
    private _energyDirection = EnergyDirection.Import;

    private _points: PeriodicData<Point>[] = [];
    private _buildings: Building[] = [];
    private _currentPoint: string | null = null;
    private _currentPointCommonProperties: PointCommonProperties | null = null;

    private _loadingPointCommonProperties = false;

    get loadingPointCommonProperties(): boolean {
        return this._loadingPointCommonProperties;
    }

    get energyForPower(): boolean {
        return this._energyForPower;
    }

    get energyType(): EnergyType {
        return this._energyType;
    }

    get energyDirection(): EnergyDirection {
        return this._energyDirection;
    }

    get currentPoint(): string | null {
        return this._currentPoint;
    }

    get currentPointCommonProperties(): PointCommonProperties | null {
        return this._currentPointCommonProperties;
    }

    get points(): PeriodicData<Point>[] {
        return this._points;
    }

    get buildings(): Building[] {
        return this._buildings;
    }

    get currentPointMinCheckDate(): Dayjs {
        return dayjs(this._currentPointMinCheckDate);
    }

    get beginCheckDate(): Dayjs {
        return dayjs(this._beginCheckDate);
    }

    get endCheckDate(): Dayjs {
        return dayjs(this._endCheckDate);
    }

    @Mutation
    setLoadingPointCommonProperties(value: boolean): void {
        this._loadingPointCommonProperties = value;
    }

    @Mutation
    setEnergyForPower(value: boolean): void {
        this._energyForPower = value;
    }

    @Mutation
    setEnergyType(value: EnergyType): void {
        this._energyType = value;
    }

    @Mutation
    setEnergyDirection(value: EnergyDirection): void {
        this._energyDirection = value;
    }

    @Mutation
    setCurrentPoint(currentPoint: Guid | null): void {
        this._currentPoint = currentPoint ? currentPoint.toString() : null;
    }

    @Mutation
    setCurrentPointCommonProperties(currentPointCommonProperties: PointCommonProperties | null): void {
        this._currentPointCommonProperties = currentPointCommonProperties;
    }

    @Mutation
    clearCurrentPointCommonProperties(): void {
        this._currentPointCommonProperties = null;
    }

    @Mutation
    clearPointCommonPropertiesCache(): void {
        this._pointCommonPropertiesCache = {};
    }

    @Mutation
    setPoints(points: PeriodicData<Point>[]): void {
        this._points = points;
    }

    @Mutation
    setBuildings(buildings: Building[]): void {
        this._buildings = buildings;
    }

    @Mutation
    clearBuildings(): void {
        this._buildings = new Array<Building>();
    }

    @Mutation
    setCurrentPointMinCheckDate(value: Dayjs): void {
        this._currentPointMinCheckDate = value.toISOString();
    }

    @Mutation
    setBeginCheckDate(value: Dayjs): void {
        this._beginCheckDate = value.toISOString();
    }

    @Mutation
    setEndCheckDate(value: Dayjs): void {
        this._endCheckDate = value.toISOString();
    }

    @Action
    unsetCurrentPoint(): void {
        this.setCurrentPoint(null);
        this.setCurrentPointCommonProperties(null);
        imsEventLogModule.setCurrentPointEventLog([]);
        imsModuleIndications.setCurrentPointIndications(null);
        imsPowersModule.setCurrentPointPowers({ powers: [] });
        imsEnergyModule.setCurrentPointEnergySet({ energies: [], totalEnergy: 0 });
        imsEnergyForPowerModule.setCurrentPointEnergyForPower({ energies: [], averageEnergyValue: 0 });
    }

    @Action
    async loadPoints(): Promise<void> {
        const proxy = await ProxyFactory.creatPointsPresentationProxy();
        const points = await proxy.getPoints();

        if (points.length == 0) {
            this.clearCache();
        }

        this.setPoints(points);
        if (this.currentPoint) {
            await this.updateCurrentPoint(Guid.parse(this.currentPoint));
        }
        else {
            await this.updateCurrentPoint(null);
        }
    }

    @Action
    async loadBuildings(data: BuildingsFilterData): Promise<void> {
        const proxy = await ProxyFactory.createReferenceDataPresentationProxy();
        const buildings = await proxy.getBuildings(data.searchName, data.contractId);
        this.setBuildings(buildings);
    }

    @Action
    clearCache(): void {
        this.clearCurrentPointCommonProperties();
        this.clearPointCommonPropertiesCache();
    }

    @Action
    async updateCurrentPoint(pointId: Guid | null): Promise<void> {

        if (this.points.length == 0) {
            return;
        }

        let selectedPoint = this.points.find(p => p.data.id == pointId);
        if (!selectedPoint) {
            selectedPoint = this.points[0];
        }

        await this.updateCurrentPointData(selectedPoint);
    }

    @Action
    async updateCurrentPointData(currentPoint: PeriodicData<Point>): Promise<void> {
        this.setCurrentPoint(currentPoint.data.id);

        try {
            this.actualizeCheckPoints(currentPoint);
            const commonLoader = this.updateCurrentPointCommonData();
            const indicationLoader = imsModuleIndications.updateCurrentPointIndicationData();
            const powerLoader = imsPowersModule.updateCurrentPointPowerData();
            const energyLoader = imsEnergyModule.updateCurrentPointEnergyData();
            const energyForPowerLoader = imsEnergyForPowerModule.updateCurrentPointEnergyForPowerData();
            const eventLoader = imsEventLogModule.updateCurrentPointEventLogData();
            await Promise.allSettled([commonLoader, indicationLoader, powerLoader, energyLoader, energyForPowerLoader, eventLoader]);
        }
        catch (error) {
            Logger.logError(`updateCurrentPointData FAILED ${currentPoint.data.id}`, error);
        }
    }

    @Action
    async updateCurrentPointDataDatePeriodDependent(value: PeriodDependent): Promise<void> {
        if (this.currentPoint) {
            const pointId = Guid.parse(this.currentPoint);
            const selectedPoint = this.points.find(p => p.data.id == pointId);

            try {
                if (!selectedPoint) {
                    throw 'Selected point not found';
                }

                imsModule.setBeginCheckDate(value.beginDate);
                imsModule.setEndCheckDate(value.endDate);

                imsModuleIndications.updateCurrentPointIndicationData();
                imsPowersModule.updateCurrentPointPowerData();
                imsEnergyModule.updateCurrentPointEnergyData();
                imsEventLogModule.updateCurrentPointEventLogData();
            }
            catch (error) {
                Logger.logError(`updateCurrentPointRelatedDate FAILED ${this.currentPoint}`, error);
            }
        }
    }

    @Action
    async updateCurrentPointDataEnergyParametersDependent(value: EnergyDependent): Promise<void> {
        if (this.currentPoint) {
            const pointId = Guid.parse(this.currentPoint);
            const selectedPoint = this.points.find(p => p.data.id == pointId);

            try {
                if (!selectedPoint) {
                    throw 'Selected point not found';
                }

                if (value.typeEnergy !== undefined) {
                    imsModule.setEnergyType(value.typeEnergy ? EnergyType.Active : EnergyType.Reactive);
                }

                if (value.directionEnergy !== undefined) {
                    imsModule.setEnergyDirection(value.directionEnergy ? EnergyDirection.Import : EnergyDirection.Export);
                }

                imsModuleIndications.updateCurrentPointIndicationData();
                imsEnergyModule.updateCurrentPointEnergyData();
            }
            catch (error) {
                Logger.logError(`updateCurrentPointRelatedEnergy FAILED ${this.currentPoint}`, error);
            }
        }
    }

    @Action
    actualizeCheckPoints(currentPoint: PeriodicData<Point>): void {
        if (currentPoint) {
            let pointLinkBeginDate = dayjs(currentPoint.beginDate);
            if (currentPoint.data.imsEntryDate != null) {
                const imsBeginDate = dayjs(currentPoint.data.imsEntryDate);
                pointLinkBeginDate = dayjs.max(pointLinkBeginDate, imsBeginDate);
            }

            this.setCurrentPointMinCheckDate(pointLinkBeginDate);
            if (this.beginCheckDate < pointLinkBeginDate) {
                this.setBeginCheckDate(pointLinkBeginDate);
            }
        }
    }

    @Action
    async updateCurrentPointCommonData(): Promise<void> {
        if (!this.currentPoint) {
            return;
        }

        const pointIdAsGuid = Guid.parse(this.currentPoint);

        this.setLoadingPointCommonProperties(true);
        try {
            let commonProperties: PointCommonProperties;
            if (this._pointCommonPropertiesCache && this._pointCommonPropertiesCache[this.currentPoint]) {
                commonProperties = this._pointCommonPropertiesCache[this.currentPoint];
            }
            else {
                const proxy = await ProxyFactory.creatPointsPresentationProxy();
                commonProperties = await proxy.getPointCommonProperties(pointIdAsGuid);
                this._pointCommonPropertiesCache[this.currentPoint] = commonProperties;
            }
            this.setCurrentPointCommonProperties(commonProperties);
        }
        catch (exc) {
            this.setCurrentPointCommonProperties(null);
            Logger.logError("updateCurrentPointCommonData FAILED!", exc)
            throw exc;
        }
        finally {
            this.setLoadingPointCommonProperties(false);
        }
    }
}

export const imsModule = getModule(ImsModule);