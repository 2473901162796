import { Logger } from './common/logger';
import { ApplicationConfiguration, ApplicationSettingsHolder } from './configurations/applicationConfiguration';

export class ApplicationInitializer {
    
    public static async initialize(): Promise<void> {
        Logger.logInfo("Initialize settings");
        const configuration = await this.loadConfiguration();
        ApplicationSettingsHolder.initialize(configuration);       
    }

    private static async loadConfiguration(): Promise<ApplicationConfiguration> {
        const configRaw = await fetch("config/appsettings.json");
        const result = await configRaw.json();
        return Object.assign({}, result) as ApplicationConfiguration;
    }
}