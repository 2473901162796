import { Guid } from "guid-typescript";
import { ProxyBase } from "./common";
import { NewLegalPersonContractOrderData } from "./dataContracts";


export class RegistrationManagerProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ManagementObjectsRegistration/", token, tokenType)
    }

    public async addPrivatePersonContractOrder(contractNumber: string, validationCode: string, emailReceipt: boolean): Promise<Guid> {
        const action = "AddPrivatePersonContractOrder"

        return await this.postDataFromAction<Guid>(action, {
            contractNumber,
            validationCode,
            emailReceipt
        })
    }

    public async addLegalPersonContractOrder(registrationData: NewLegalPersonContractOrderData): Promise<Guid> {
        const action = "AddLegalPersonContractOrder";

        const formData = new FormData();
        formData.append('contractNumber', registrationData.contractNumber);
        formData.append('partnerInn', registrationData.partnerInn);
        registrationData.files.forEach((file: File) => formData.append('files', file));

        return await this.postDataFromAction<Guid>(action, formData);
    }

    public async validateLegalPersonContractOrder(validationData: NewLegalPersonContractOrderData): Promise<void> {
        const action = "ValidateLegalPersonContractOrder";

        await this.postDataFromActionWithoutResult(action, validationData);
    }

    public async removePrivatePersonContract(contractId: string): Promise<void> {
        const action = "RemovePrivatePersonContract"

        return await this.deleteDataFromAction(action, {
            contractId: contractId
        })
    }

    public async removeLegalPersonContract(contractId: string): Promise<void> {
        const action = "RemoveLegalPersonContract"

        return await this.deleteDataFromAction(action, {
            contractId: contractId
        })
    }
}