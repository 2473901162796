import { ProxyBase } from "./common"
import { TariffZone } from "./dataContracts"
import { ReceiptOptions } from "./dataContracts/personalAccount"

export class PersonalAccountManagementProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "PersonalAccountManager/", token, tokenType)
    }

    public addIndication(contractId: string, meterId: string, pointId: string, indications: { tariffZone: TariffZone, value: number }[]): Promise<void> {
        return this.postDataFromActionWithoutResult("AddMeteringData", {
            contractId,
            meterId,
            pointId,
            meterings: indications
        })
    }

    public updateReceiptOptions(options: ReceiptOptions): Promise<void> {
        return this.postDataFromActionWithoutResult("UpdateReceiptOptions", options)
    }
}