import { TechConnectionDetails, TechConnectionFileType } from './../../apiProxies/dataContracts';
import { store } from "../index"
import { TechConnection } from "@/apiProxies/dataContracts";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ProxyFactory from "@/apiProxies/proxyFactory";
import { preserveModule } from '../storeObject';

const moduleName = 'techConnecionModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, stateFactory: true, preserveState: preserveModule(moduleName) })
export class TechConnecionModule extends VuexModule {

    private _techConnectionRows: TechConnection[] = [];
    private _techConnectionDetails: TechConnectionDetails | undefined;

    public get techConnectionRows(): TechConnection[] {
        return this._techConnectionRows;
    }

    public get techConnectionDetails(): TechConnectionDetails | undefined {
        return this._techConnectionDetails;
    }

    @Mutation
    public setTechConnectionRows(techConnecitonRows: TechConnection[]): void {
        this._techConnectionRows = techConnecitonRows;
    }

    @Mutation
    public setTechConnectionDetails(techConnectionDetails: TechConnectionDetails | undefined): void {
        this._techConnectionDetails = techConnectionDetails;
    }

    @Action({ rawError: true })
    public async loadTechConnectionDetails(techConnectionId: string): Promise<void> {
        const proxy = await ProxyFactory.createTechConnectionPresenterProxy();

        const requests = await proxy.getTechConnectionDetails(techConnectionId);

        this.setTechConnectionDetails(requests);
    }

    @Action({ rawError: true })
    public async loadTechConnectionRows(): Promise<void> {
        const proxy = await ProxyFactory.createTechConnectionPresenterProxy();

        const rows = await proxy.getTechConnectionRows();

        this.setTechConnectionRows(rows);
    }

    @Action({ rawError: true })
    public async addTechConnectionFile(data: AddTechConnectionFile): Promise<void> {
        const proxy = await ProxyFactory.createTechConnectionManagerProxy();

        await proxy.addTechConnectionFile(data.techConnectionId, data.fileType, data.file);
    }

    @Action({ rawError: true })
    public async deleteTechConnectionFile(fileId: string): Promise<void> {
        const proxy = await ProxyFactory.createTechConnectionManagerProxy();

        await proxy.deleteTechConnectionFile(fileId);
    }

    @Action({ rawError: true })
    public async downloadFile(fileId: string): Promise<Blob> {
        const proxy = await ProxyFactory.createTechConnectionPresenterProxy();

        return await proxy.downloadTechConnectionFile(fileId);
    }
}

export type AddTechConnectionFile = {
    techConnectionId: string,
    fileType: TechConnectionFileType,
    file: File
}

export const techConnectionModule = getModule(TechConnecionModule);