import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { MeterDeviceIndicationSet } from "@/apiProxies/dataContracts";
import ProxyFactory from "@/apiProxies/proxyFactory";
import dayjs from "dayjs";
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript"
import { imsModule } from "@/store/modules/ims";
import { preserveModule } from "../storeObject";

const moduleName = 'imsIndicationsModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsIndicationsModule extends VuexModule {

    private _currentPointIndications: MeterDeviceIndicationSet | null = null;

    private _loadPointIndicationData = false;

    get loadingPointIndicationData(): boolean {
        return this._loadPointIndicationData;
    }

    get currentPointIndication(): MeterDeviceIndicationSet | null {
        return this._currentPointIndications;
    }

    @Mutation
    setLoadingPointIndicationData(value: boolean): void {
        this._loadPointIndicationData = value;
    }

    @Mutation
    setCurrentPointIndications(currentPointIndications: MeterDeviceIndicationSet | null): void {
        this._currentPointIndications = currentPointIndications;
    }

    @Action({ rawError: true })
    async updateCurrentPointIndicationData(): Promise<void> {
        try {
            this.setLoadingPointIndicationData(true);
            const proxy = await ProxyFactory.createMeterDeviceDataPresentationProxy();

            if (imsModule.currentPoint && dayjs.isDayjs(imsModule.beginCheckDate) && dayjs.isDayjs(imsModule.endCheckDate)) {
                const pointIdAsGuid = Guid.parse(imsModule.currentPoint);

                const indications = await proxy.getMeterDeviceIndications(pointIdAsGuid, imsModule.energyType, imsModule.energyDirection,
                    imsModule.beginCheckDate.toDate(), imsModule.endCheckDate.toDate());
                this.setCurrentPointIndications(indications);
            }
        }
        catch (exc) {
            Logger.logError("updateCurrentPointIndicationData FAILED!", exc)
            throw exc;
        }
        finally {
            this.setLoadingPointIndicationData(false);
        }
    }
}

export const imsModuleIndications = getModule(ImsIndicationsModule);