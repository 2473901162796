import { ProxyBase } from "./common";
import { TechConnectionFileType } from "./dataContracts";

export class TechConnectionManagerProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "TechConnectionManager/", token, tokenType)
    }

    public addTechConnectionFile(techConnectionId: string, fileType: TechConnectionFileType, file: File): Promise<void> {
        const action = "AddTechConnectionFile";

        const formData = new FormData();
        formData.append('techConnectionId', techConnectionId);
        formData.append('fileType', fileType.toString());
        formData.append('file', file);

        return this.postDataFromActionWithoutResult(action, formData);
    }

    public deleteTechConnectionFile(fileId: string): Promise<void> {
        const action = "DeleteTechConnectionFile";

        return this.postDataFromActionWithoutResult(action, {
            fileId: fileId
        });
    }
}