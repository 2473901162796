export type Notice = {
    id: string,
    title: string,
    content: string,
    date: string,
    state: NoticeState
}

export enum NoticeState {
    unread,
    read
}

export type NoticeFilters = {
    notificationState?: NoticeState,
    pages?: PageFilter,
    dateRange?: DateRangeFilter,
    active?: boolean
}

export type PageFilter = {
    size: number,
    page: number
}

export type DateRangeFilter = {
    beginDate: string,
    endDate: string
}