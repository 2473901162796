export class Logger {

    public static logSuccess(message: string, ...rest: any[]): void {
        console.log(`%c${message}`, 'padding: 8px 12px; background: lightgreen', ...rest);
    }

    public static logWarning(message: string, ...rest: any[]): void {
        console.log(`%c${message}`, 'padding: 8px 12px; background: orange', ...rest);
    }

    public static logError(message: string, ...rest: any[]): void {
        console.log(`%c${message}`, 'padding: 8px 12px; background: tomato; color: #000000', ...rest);
    }

    public static logInfo(message: string, ...rest: any[]): void {
        console.log(`%c${message}`, 'padding: 8px 12px; background: lightblue; color: indigo', ...rest);
    }
}