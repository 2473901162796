import { has } from "lodash"

let value: unknown | undefined

export const preserveModule = (moduleName: string): boolean => {
    if (!value) {
        const ims = localStorage.getItem('ims')

        if (ims) {
            value = JSON.parse(ims)
        }
    }

    if (value) {
        return has(value, moduleName)
    }

    return false
}