import { getFormattedBeginDate, getFormattedEndDate } from "@/common/dateHelper"
import { ProxyBase } from "./common"
import { LegalPersonContractData, PrivatePersonContractData } from "./dataContracts"
import { Indication, IndicationType, MeterDeviceData, PersonalAccountInfo, ReceiptOptions } from "./dataContracts/personalAccount"

export class PersonalAccountPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "PersonalAccountPresenter/", token, tokenType)
    }

    public getMeterDeviceData(contractId: string): Promise<MeterDeviceData[]> {
        return this.getDataFromAction<MeterDeviceData[]>("GetMeterDeviceData", { contractId })
    }

    public getIndications(contractId: string, meterId: string | null, beginDate: Date, endDate: Date, indiactionType: IndicationType): Promise<Indication[]> {
        const params: Record<string, string> = {
            contractId: contractId,
            meteringType: indiactionType.toString(),
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        }

        if (meterId) {
            params.meterId = meterId
        }

        return this.getDataFromAction<Indication[]>("GetIndications", params)
    }

    public getPrivateContracts(): Promise<PrivatePersonContractData[]> {
        return this.getDataFromAction<PrivatePersonContractData[]>("GetPrivateContracts")
    }

    public getLegalContracts(): Promise<LegalPersonContractData[]> {
        return this.getDataFromAction<LegalPersonContractData[]>("GetLegalContracts")
    }

    public getReceiptOptions(contractId: string): Promise<ReceiptOptions> {
        return this.getDataFromAction<ReceiptOptions>("GetReceiptOptions", { contractId })
    }

    public getInformation(contractId: string): Promise<PersonalAccountInfo> {
        return this.getDataFromAction<PersonalAccountInfo>("GetInformation", { contractId })
    }
}