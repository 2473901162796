import { Guid } from "guid-typescript";
import { ProxyBase } from "./common";
import { Building } from "./dataContracts";

export class ReferenceDataPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ReferenceData/", token, tokenType)
    }

    public async getBuildings(searchName: string, contractId?: Guid, size?: number): Promise<Building[]> {
        const params: Record<string, string> = {
            searchName
        }

        if (contractId) {
            params.contractId = contractId.toString();
        }

        if (size) {
            params.size = size.toString();
        }

        return await this.getDataFromAction<Building[]>("GetBuildings", params);
    }
}