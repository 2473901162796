import { Notice, NoticeFilters, NoticeState } from "@/types/notifications"
import { ProxyBase } from "./common"

export class NotificationsProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "Notifications/", token, tokenType)
    }

    public getUnreadCount(): Promise<number> {
        const action = "GetUnreadCount"

        return this.getDataFromAction<number>(action)
    }

    public getNotification(noticeId: string): Promise<Notice | undefined> {
        const action = "GetNotification"

        return this.getDataFromAction<Notice | undefined>(action, {
            noticeId
        })
    }

    public getNotifications(filters?: NoticeFilters): Promise<Notice[]> {
        const action = "GetNotifications"

        const params: Record<string, string> = {}

        filters && NotificationsProxy.setFilters(filters, params)

        return this.getDataFromAction<Notice[]>(action, params)
    }

    public setState(noticeId: string, state: NoticeState): Promise<void> {
        const action = "SetState"

        return this.postDataFromActionWithoutResult(action, {
            noticeId,
            state
        })
    }

    private static setFilters<T>(filters: T, params: Record<string, string>, prefix = ''): void {
        for (const key in filters) {
            const value = filters[key as keyof T]
            if (typeof value === 'object') {
                this.setFilters(value, params, key + '.')
            }
            else if (value !== null && value !== undefined && typeof value === 'number' || typeof value === 'bigint' || typeof value === 'string' || typeof value === 'boolean' || typeof value === 'symbol') {
                params[prefix + key] = value.toString()
            }
        }
    }
}