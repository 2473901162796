import { Guid } from "guid-typescript";
import { ProxyBase } from "./common";
import { PointCommonProperties, Point, PeriodicData } from "./dataContracts";

export class PointsPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ImsPointsPresenter/", token, tokenType)
    }

    public async getPoints(): Promise<PeriodicData<Point>[]> {
        return await this.getDataFromAction<PeriodicData<Point>[]>("GetPoints");
    }

    public async getPointCommonProperties(pointId: Guid): Promise<PointCommonProperties> {
        return await this.getDataFromAction<PointCommonProperties>("GetPointCommonProperties", {
            pointId: pointId.toString(),
        })
    }

    public async getPointsWithFilters(pointName?: string, contractId?: Guid, buildingId?: Guid): Promise<PeriodicData<Point>[]> {
        const params: Record<string, string> = {};

        if(pointName) {
            params.pointName = pointName;
        }

        if (contractId) {
            params.contractId = contractId.toString();
        }

        if (buildingId) {
            params.buildingId = buildingId.toString();
        }

        return await this.getDataFromAction<PeriodicData<Point>[]>("GetPointsWithFilters", params);
    }
}