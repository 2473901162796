import { ApplicationSettingsHolder } from "@/configurations/applicationConfiguration";
import { authenticationModule } from "../store/modules/authentication";
import { ManagementObjectsPresentationProxy } from "./managementObjectsPresentationProxy";
import { RegistrationManagerProxy } from "./registrationManagerProxy";
import { PointsPresentationProxy } from "./pointsPresentationProxy";
import { MeterDeviceDataPresentationProxy } from "./meterDeviceDataPresentationProxy";
import { MeterDeviceEventLogPresentationProxy } from "./meterDeviceEventLogPresentationProxy";
import { ReferenceDataPresentationProxy } from "./referenceDataPresentationProxy";
import { UserProfileManagerProxy } from "./userProfileManageProxy";
import { ConfigurationPresentationProxy } from "./configurationPresentationProxy";
import { TechConnectionPresenterProxy } from "./techConnectionPresenterProxy";
import { TechConnectionManagerProxy } from "./techConnectionManagerProxy";
import { PersonalAccountPresentationProxy } from "./personalAccountPresentationProxy";
import { PersonalAccountManagementProxy } from "./personalAccountManagementProxy";
import { PaymentDocumentsPresenterProxy } from "./paymentDocumentsPresenterProxy";
import { AppealsPresenterProxy } from "./appealsPresenterProxy";
import { AppealsManagerProxy } from "./appealsManagerProxy";
import { BillingPresenterProxy } from "./billingPresentationProxy";
import { PaymentProxy } from "./paymentProxy";
import { NotificationsProxy } from "./notificationsProxy";

export default class ProxyFactory {
  private static async getAccessToken(): Promise<string> {
    const token = await authenticationModule.accessToken;
    if (token == null) {
      throw "Current user not authenticated";
    } else {
      return token;
    }
  }

  private static async getTokenType(): Promise<string> {
    const tokenType = await authenticationModule.tokenType;
    if (tokenType == null) {
      throw "User token type not defined";
    } else {
      return tokenType;
    }
  }

  public static async creatPointsPresentationProxy(): Promise<
    PointsPresentationProxy
  > {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new PointsPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createManagementObjectsPresentationProxy(): Promise<
    ManagementObjectsPresentationProxy
  > {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new ManagementObjectsPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.managementObjectsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createRegistrationManagerProxy(): Promise<
    RegistrationManagerProxy
  > {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new RegistrationManagerProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.registrationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createMeterDeviceDataPresentationProxy(): Promise<
    MeterDeviceDataPresentationProxy
  > {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new MeterDeviceDataPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.registrationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createConfigurationPresentationProxy(): Promise<ConfigurationPresentationProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new ConfigurationPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createMeterDeviceEventLogPresentationProxy(): Promise<
    MeterDeviceEventLogPresentationProxy
  > {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new MeterDeviceEventLogPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createReferenceDataPresentationProxy(): Promise<ReferenceDataPresentationProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new ReferenceDataPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createUserProfileManagerProxy(): Promise<UserProfileManagerProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new UserProfileManagerProxy(
      ApplicationSettingsHolder.configuration.authentication.openIdServiceUrl,
      token,
      tokenType
    );
  }

  public static async createTechConnectionPresenterProxy(): Promise<TechConnectionPresenterProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new TechConnectionPresenterProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createTechConnectionManagerProxy(): Promise<TechConnectionManagerProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new TechConnectionManagerProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createPersonalAccountPresenterProxy(): Promise<PersonalAccountPresentationProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new PersonalAccountPresentationProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createPersonalAccountManagerProxy(): Promise<PersonalAccountManagementProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new PersonalAccountManagementProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createPaymentDocumentsPresenterProxy(): Promise<PaymentDocumentsPresenterProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new PaymentDocumentsPresenterProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }
  
  public static async createAppealsPresenterProxy(): Promise<AppealsPresenterProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new AppealsPresenterProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }

  public static async createAppealsManagerProxy(): Promise<AppealsManagerProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new AppealsManagerProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }
  
  public static async createBillingPresenterProxy(): Promise<BillingPresenterProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new BillingPresenterProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }
  
  public static async createPaymentProxy(): Promise<PaymentProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new PaymentProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }
  
  public static async createNotificationsProxy(): Promise<NotificationsProxy> {
    const token = await this.getAccessToken();
    const tokenType = await this.getTokenType();

    return new NotificationsProxy(
      ApplicationSettingsHolder.configuration.apiConfiguration.pointsPresentationServiceUrl,
      token,
      tokenType
    );
  }
}
