import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { MeterDevicePowerSet } from "@/apiProxies/dataContracts";
import ProxyFactory from "@/apiProxies/proxyFactory";
import dayjs from "dayjs";
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript"
import { imsModule } from "@/store/modules/ims";
import { DateHelper } from "@/common/dateSelectorHelper";
import { preserveModule } from "../storeObject";

const moduleName = 'imsPowersModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsPowersModule extends VuexModule {

    private _currentPointPowers: MeterDevicePowerSet | null = null;

    private _loadPointPowerData = false;

    get loadingPointPowerData(): boolean {
        return this._loadPointPowerData;
    }

    get currentPointPower(): MeterDevicePowerSet | null {
        return this._currentPointPowers;
    }

    @Mutation
    setLoadingPointPowerData(value: boolean): void {
        this._loadPointPowerData = value;
    }

    @Mutation
    setCurrentPointPowers(currentPointPowers: MeterDevicePowerSet | null): void {
        this._currentPointPowers = currentPointPowers;
    }

    @Action({ rawError: true })
    async updateCurrentPointPowerData(): Promise<void> {
        try {
            this.setLoadingPointPowerData(true);
            const proxy = await ProxyFactory.createMeterDeviceDataPresentationProxy();

            if (imsModule.currentPoint && dayjs.isDayjs(imsModule.beginCheckDate) && dayjs.isDayjs(imsModule.endCheckDate)) {
                const pointId = Guid.parse(imsModule.currentPoint);

                const monthRange = DateHelper.getMonthRangeDates(imsModule.beginCheckDate.toDate(), imsModule.endCheckDate.toDate())
                
                if (!monthRange) {
                    return this.setCurrentPointPowers(null);
                }

                const meterPowers = await proxy.getMeterDevicePowers(pointId, monthRange[0], monthRange[1]);
                this.setCurrentPointPowers(meterPowers);
            }
        }
        catch (exc) {
            Logger.logError("updateCurrentPointPowerData FAILED!", exc)
            throw exc;
        }
        finally {
            this.setLoadingPointPowerData(false);
        }
    }
}

export const imsPowersModule = getModule(ImsPowersModule);