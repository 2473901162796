import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { store } from "../index"
import { Notice, NoticeFilters, NoticeState } from '@/types/notifications'
import ProxyFactory from "@/apiProxies/proxyFactory"
import { isEmpty } from "lodash"

@Module({ store, name: 'notifications', namespaced: true, dynamic: true })
export class NotificationsModule extends VuexModule {
    private _unredCount = 0
    private _nothingLoading = false
    private _notifications: Array<Notice> = []
    private _unreadNotifications: Array<Notice> = []

    private readonly _pageDefault = {
        page: 1,
        size: 25
    }
    private _notificationsFilters: NoticeFilters = {
        notificationState: undefined,
        dateRange: undefined,
        pages: { ...this._pageDefault }
    }

    public get notices(): Notice[] {
        return this._notifications
    }

    public get unreadNotices(): Notice[] {
        return this._unreadNotifications
    }

    public get noticeFilters(): NoticeFilters {
        return { ...this._notificationsFilters }
    }

    public get unreadCount(): number {
        return this._unredCount
    }

    @Mutation
    private setNotifications(value: Notice[]): void {
        this._notifications = value
    }

    @Mutation
    private setUnreadNotifications(value: Notice[]): void {
        this._unreadNotifications = value
    }

    @Mutation
    private setNotificationsFilters(value: NoticeFilters): void {
        this._notificationsFilters = value
    }

    @Mutation
    public setFiltersDateRange(dateRange: { beginDate: string, endDate: string } | undefined): void {
        this._notificationsFilters = {
            ...this._notificationsFilters,
            dateRange
        }
    }

    @Mutation
    public setFiltersPageDefault(): void {
        this._notificationsFilters = {
            ...this._notificationsFilters,
            pages: {
                ...this._pageDefault
            }
        }
    }

    @Mutation
    public setUnreadCount(value: number): void {
        this._unredCount = value
    }

    @Mutation
    public setNothingLoading(value: boolean): void {
        this._nothingLoading = value
    }

    @Action
    public async loadNotices(): Promise<void> {
        const proxy = await ProxyFactory.createNotificationsProxy()

        const result = await proxy.getNotifications(this.noticeFilters)

        this.setNotifications(result)
    }

    @Action
    public async loadNoticesPagination(): Promise<void> {
        if (!this.noticeFilters.pages || this._nothingLoading || this._notifications.length < this.noticeFilters.pages.size) return

        this.setNotificationsFilters({
            ...this.noticeFilters, pages: {
                page: this.noticeFilters.pages.page + 1,
                size: this.noticeFilters.pages.size
            }
        });

        const size = this.noticeFilters.pages.size
        const page = this.noticeFilters.pages.page

        const proxy = await ProxyFactory.createNotificationsProxy()

        let result = await proxy.getNotifications(this.noticeFilters)

        if (result.length < size) {
            this.setNothingLoading(true)
        }

        if (page > 1) {
            result = this._notifications.concat(result)
        }

        if (!isEmpty(result)) {
            this.setNotifications(result)
        }
    }

    @Action
    public async loadUnreadNotices(): Promise<void> {
        const proxy = await ProxyFactory.createNotificationsProxy()

        const result = await proxy.getNotifications({
            notificationState: NoticeState.unread,
            active: true
        })

        this.setUnreadNotifications(result)
    }

    @Action
    public async getNotice(noticeId: string): Promise<Notice | undefined> {
        const proxy = await ProxyFactory.createNotificationsProxy()

        return await proxy.getNotification(noticeId)
    }

    @Action
    public async readNotice(noticeId: string): Promise<void> {
        const proxy = await ProxyFactory.createNotificationsProxy()

        await proxy.setState(noticeId, NoticeState.read)
        const count = await proxy.getUnreadCount()
        this.setUnreadCount(count)
    }

    @Action
    public async loadUnreadCount(): Promise<void> {
        const proxy = await ProxyFactory.createNotificationsProxy()

        const count = await proxy.getUnreadCount()

        this.setUnreadCount(count)
    }
}

export const noticeModule = getModule(NotificationsModule)