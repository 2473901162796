import { ProxyBase } from "./common";

export class UserProfileManagerProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "UserProfileManager/", token, tokenType)
    }
    
    public changePassword(currentPassword: string, newPassword: string): Promise<void> {
        const action = "ChangePassword";

        return this.postDataFromActionWithoutResult(action, {
            currentPassword,
            newPassword
        });
    }

    public verifyPhone(phone: string): Promise<void> {
        const action = "VerifyPhone";

        return this.postDataFromActionWithoutResult(action, {
            phone
        });
    }

    public changeNumber(phone: string, code: string): Promise<void> {
        const action = "ChangeNumber";

        return this.postDataFromActionWithoutResult(action, {
            phone,
            code
        });
    }

    public cancelPhoneVerifing(): Promise<void> {
        const action = "CancelPhoneVerification";

        return this.postDataFromActionWithoutResult(action, {});
    }

    public checkEmail(email: string): Promise<void> {
        const action = "CheckEmail"

        return this.postDataFromActionWithoutResult(action, {
            email
        })
    }

    public changeEmail(email: string, code: string): Promise<void> {
        const action = "ChangeEmail"

        return this.postDataFromActionWithoutResult(action, {
            email,
            code
        })
    }
}