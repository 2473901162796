import { ProxyBase } from "./common";
import { TechConnectionDetails, TechConnection } from "./dataContracts";

export class TechConnectionPresenterProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "TechConnectionPresenter/", token, tokenType)
    }

    public getTechConnectionRows(): Promise<TechConnection[]> {
        const action = "GetTechConnections";

        return this.getDataFromAction(action);
    }

    public getTechConnectionDetails(techConnectionId: string): Promise<TechConnectionDetails> {
        const action = "GetTechConnectionDetails";

        return this.getDataFromAction(action, {
            techConnectionId: techConnectionId.toString()
        });
    }

    public downloadTechConnectionFile(fileId: string): Promise<Blob> {
        const action = "GetTechConnectionFile";

        return this.downloadData(action, {
            fileId: fileId
        });
    }
}