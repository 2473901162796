import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import Datepicker from '@vuepic/vue-datepicker'
import 'dayjs/locale/ru'
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import App from './App.vue'
import router from './router'
import { store } from './store'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import './assets/ims-ant.less'
import '@vuepic/vue-datepicker/dist/main.css'
import { ApplicationInitializer } from './applicationInitializer'

ApplicationInitializer.initialize()
    .then(() => {
        const app = createApp(App)
            .use(store)
            .use(router)
            .use(Antd)
            .use(PerfectScrollbar, { name: 'scroll' })

        app.component('Datepicker', Datepicker)

        app.mount('#app')
    })
