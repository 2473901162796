import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { EventTypeData, MeterDeviceEvent, MeterDeviceEventLogData } from "@/apiProxies/dataContracts";
import ProxyFactory from "@/apiProxies/proxyFactory";
import dayjs from "dayjs";
import { Logger } from "@/common/logger";
import { imsModule } from "@/store/modules/ims";
import { preserveModule } from "../storeObject";

const moduleName = 'imsEventLogModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsEventLogModule extends VuexModule {

    private _currentPointEvents: string[] | null = null;
    private _currentPointEventLog: MeterDeviceEventLogData[] | null = null;
    private _currentPointEventTypes: EventTypeData[] = [];
    private _loadingPointEvntLogData = false;

    get loadingPointEventLogData(): boolean {
        return this._loadingPointEvntLogData;
    }

    get currentPointEventLog(): MeterDeviceEventLogData[] | null {
        return this._currentPointEventLog;
    }

    get currentPointEventTypes(): EventTypeData[] {
        return this._currentPointEventTypes;
    }

    get currentPointEvents(): string[] | null {
        return this._currentPointEvents;
    }

    @Mutation
    setLoadintPointEvntLogData(value: boolean): void {
        this._loadingPointEvntLogData = value;
    }

    @Mutation
    setCurrentPointEventLog(currentPointEventLog: MeterDeviceEventLogData[]): void {
        this._currentPointEventLog = currentPointEventLog;
    }

    @Mutation
    setCurrentPointEvents(events: string[]): void {
        this._currentPointEvents = events;
    }

    @Mutation
    setCurrentPointEventTypes(types: EventTypeData[]): void {
        this._currentPointEventTypes = types;
    }

    @Action({ rawError: true })
    async updateCurrentPointEventLogData(): Promise<void> {
        try {
            this.setLoadintPointEvntLogData(true);
            const proxy = await ProxyFactory.createMeterDeviceEventLogPresentationProxy();

            if (imsModule.currentPoint && dayjs.isDayjs(imsModule.beginCheckDate) && dayjs.isDayjs(imsModule.endCheckDate)) {
                const meterDeviceLog = await proxy.getMeterDeviceEventLog(
                    imsModule.currentPoint,
                    this.currentPointEvents,
                    imsModule.beginCheckDate.toDate(),
                    imsModule.endCheckDate.toDate()
                );
                this.setCurrentPointEventLog(meterDeviceLog);
            }
        }
        catch (exc) {
            Logger.logError("updateCurrentPointEventLogData FAILED!", exc)
            throw exc;
        }
        finally {
            this.setLoadintPointEvntLogData(false);
        }
    }

    @Action({ rawError: true })
    async updateCurrentPointEventTypesData(): Promise<void> {
        try {
            const proxy = await ProxyFactory.createMeterDeviceEventLogPresentationProxy();

            const meterDeviceTypes = await proxy.getMeterDeviceEventTypes();
            this.setCurrentPointEventTypes(meterDeviceTypes);
        }
        catch (exc) {
            Logger.logError("updateCurrentPointEventTypesData FAILED!", exc)
            throw exc;
        }
    }

    @Action({ rawError: true })
    async getCurrentPointEvents(type: EventTypeData): Promise<MeterDeviceEvent[]> {
        try {
            const proxy = await ProxyFactory.createMeterDeviceEventLogPresentationProxy();

            return await proxy.getMeterDeviceEvents(type);
        }
        catch (exc) {
            Logger.logError("getCurrentPointEvents FAILED!", exc)
            throw exc;
        }
    }

}

export const imsEventLogModule = getModule(ImsEventLogModule);