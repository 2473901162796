import { ApplicationSettingsHolder, AuthenticationConfiguration } from '@/configurations/applicationConfiguration';
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { UserManager, WebStorageStateStore, UserManagerSettings, Profile } from "oidc-client";
import { store } from "../index"
import { Logger } from '@/common/logger';
import { preserveModule } from '../storeObject';


export const oidcUserManager = function (): UserManager {
  const authenticationConfiguration = ApplicationSettingsHolder.configuration.authentication;

  const settings: UserManagerSettings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: authenticationConfiguration.openIdServiceUrl,
    client_id: authenticationConfiguration.clientId,
    redirect_uri: authenticationConfiguration.redirectUri,
    automaticSilentRenew: true,
    silent_redirect_uri: authenticationConfiguration.silentRedirectUri,
    response_type: authenticationConfiguration.responseType,
    scope: authenticationConfiguration.scope,
    post_logout_redirect_uri: authenticationConfiguration.postLogoutRedirectUri,
    filterProtocolClaims: true
  };

  return new UserManager(settings);
}

const moduleName = 'authenticationModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, stateFactory: true, preserveState: preserveModule(moduleName) })
export default class AuthenticationModule extends VuexModule {

  _idToken: string | null = null;
  _accessToken: string | null = null;
  _tokenType: string | null = null;
  _refreshToken?: string | null = null;
  _scopes: string[] | null = null;
  _profile: Oidc.Profile | null = null;
  _error: Error | null = null;
  _user: Oidc.User | null = null;

  private is_checked = false;
  private events_are_bound = false;

  private oidcSettings?: UserManagerSettings;
  private _oidcUserManager: UserManager | null | undefined;

  private get oidcUserManager(): UserManager {
    if (!this._oidcUserManager) {
      this._oidcUserManager = oidcUserManager();
      this._oidcUserManager.events.addAccessTokenExpired(() => {
        if (this._oidcUserManager != null) {
          //this.unSetOidcAuth();
          this._oidcUserManager.signoutRedirect()
            .then(function (resp) {
              Logger.logInfo("Signout", resp);
            }).catch(function (err) {
              Logger.logInfo("Signot error", err)
            });
        }
      });
      this._oidcUserManager.events.addUserSignedOut(() => {
        this.unSetOidcAuth();
      })
    }
    return this._oidcUserManager;
  }

  private set oidcUserManager(manager: UserManager) {
    this._oidcUserManager = manager;
  }

  public get userManager(): UserManager | null | undefined {
    return this.oidcUserManager
  }

  public get accessToken(): Promise<string | null> {
    return this.oidcUserManager.getUser()
      .then((user) => {
        if (user == null) {
          this.oidcUserManager.signinRedirect();
        }

        if (user != null) {
          if (user.expired) {
            this.oidcUserManager.signinRedirect();
          }
          return user.access_token;
        }
        else {
          return null;
        }
      })
  }

  public get getUser(): Oidc.User | null {
    return this._user;
  }

  public get profile(): Profile | null {
    return this._user && this._user.profile
  }

  public get userId(): number {
    if (this._profile) {
      const id = +this._profile.sub

      return isNaN(id) ? -1 : id
    }
    return -1
  }

  public get tokenType(): Promise<string | null> {
    return this.oidcUserManager.getUser()
      .then((user) => {
        if (user == null) {
          this.oidcUserManager.signinRedirect();
        }

        if (user != null) {
          if (user.expired) {
            this.oidcUserManager.signinRedirect();
          }
          return user.token_type;
        }
        else {
          return null;
        }
      })
  }

  public get userProfile(): Oidc.Profile | null {
    return this._profile;
  }

  @Mutation
  setUser(user: Oidc.User): void {
    this._user = user;
  }

  @Mutation
  setOidcAuth(user: Oidc.User): void {
    this._idToken = user.id_token;
    this._accessToken = user.access_token;
    this._tokenType = user.token_type;
    this._refreshToken = user.refresh_token;
    this._profile = user.profile;
    this._scopes = user.scopes;
    this._error = null;
    this._user = user;
  }

  @Mutation
  setOidcEventsAreBound(): void {
    this.events_are_bound = true;
  }

  @Mutation
  setOidcAuthIsChecked(): void {
    this.is_checked = true;
  }

  @Mutation
  public unSetOidcAuth(): void {
    this._idToken = null
    this._accessToken = null
    this._refreshToken = null
    this._profile = null
  }

  @Action
  public async isAuthenticatedUser(): Promise<boolean> {
    const user = await this.oidcUserManager.getUser();
    if (user == null || user.expired) {
      this.unSetOidcAuth();
      return false;
    }
    return true;
  }

  @Action
  public async isExpired(): Promise<boolean> {
    const user = await this.oidcUserManager.getUser()
    if (user === null || new Date() > new Date(user.expires_at * 1000)) {
      this.unSetOidcAuth()
      return true
    }
    return false
  }


  @Action
  public async authenticateOidc(): Promise<void> {
    await this.oidcUserManager.signinRedirect();
  }

  @Action
  public async oidcSignInCallback(url: string | undefined): Promise<string> {
    try {
      const user = await this.oidcUserManager.signinRedirectCallback(url);
      this.oidcWasAuthenticated(user);
      return "/";

    } catch (error) {
      this.setOidcAuthIsChecked();
      throw error;
    }
  }

  @Action
  async signOutOidc(payload: any) {
    await this.oidcUserManager.signoutRedirect(payload);
    await this.oidcUserManager.clearStaleState();
    this.unSetOidcAuth();
  }

  @Action({ rawError: true })
  oidcWasAuthenticated(user: Oidc.User): void {
    this.setOidcAuth(user);
    this.setOidcAuthIsChecked();
  }

  @Action({ rawError: true })
  async loadUserInfo(): Promise<void> {
    const user = await this.oidcUserManager.getUser()
    const config = ApplicationSettingsHolder.configuration.authentication

    if (user) {
      const response = await fetch(config.openIdServiceUrl + 'connect/userinfo', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.access_token
        }
      })

      const profile = await response.json() as Profile

      user.profile.email = profile.email
      user.profile.email_verified = profile.email_verified

      this.setUser(user)
    }
  }

  private authenticateOidcSilent(payload = {}) {
    // Take options for signinSilent from 1) payload or 2) storeSettings if defined there
    /*
    const options = payload.options || this.storeSettings.defaultSigninSilentOptions || {}
    return new Promise((resolve, reject) => {
      if (this.oidcUserManager != undefined )
      {
        this.oidcUserManager.signinSilent(options)
        .then(user => {
          this.oidcWasAuthenticated(user);
          resolve(user)
        })
        .catch(err => {
          this.setOidcAuthIsChecked();
          if (payload.ignoreErrors) {
            resolve(null)
          } else {
            //context.commit('setOidcError', errorPayload('authenticateOidcSilent', err))
            reject(err)
          }
        })
      }
    })
    */
  }
}

export const authenticationModule = getModule(AuthenticationModule);