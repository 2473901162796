import mitt from 'mitt'

export enum GlobalEventTypes {
    ApplicationMenuShow = 'ApplicationMenuShow'
}

export type GlobalEventArgs = {
    globalEvent: string
}

const emitter = mitt<GlobalEventArgs>(); // inferred as Emitter<Events>

const eventType = 'globalEvent';

export default class GlobalEventManager {
    public static FireEvent(type : GlobalEventTypes): void {
        emitter.emit(eventType, type);
    }

    public static AddEventHandler(callback: (e : string) => void){
        emitter.on(eventType, (e) => callback(e))
    }

    public static RemoveEventHandler(callback: (e : string) => void){
        emitter.off(eventType, (e) => callback(e))
    }
}