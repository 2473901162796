import { MeterDeviceEnergySet } from './../../apiProxies/dataContracts';
import { store } from "../index"
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { MeterDeviceEnergy } from "@/apiProxies/dataContracts";
import ProxyFactory from "@/apiProxies/proxyFactory";
import dayjs from "dayjs";
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript"
import { imsModule } from "@/store/modules/ims";
import { preserveModule } from '../storeObject';

const moduleName = 'imsEnergyModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class ImsEnergyModule extends VuexModule {

    private _currentPointEnergySet: MeterDeviceEnergySet = { energies: [], totalEnergy: 0 };
    private _size: number | undefined = 500;
    private _page: number | undefined = 1;
    private _nothingLoading = false;
    private _loadPointEnergyData = false;

    get loadingPointEnergyData(): boolean {
        return this._loadPointEnergyData;
    }

    get currentPointEnergySet(): MeterDeviceEnergySet {
        return this._currentPointEnergySet;
    }

    get currentPointEnergy(): MeterDeviceEnergy[] {
        return this._currentPointEnergySet.energies;
    }

    get size(): number | undefined {
        return this._size;
    }

    get page(): number | undefined {
        return this._page;
    }

    @Mutation
    setLoadingPointEnergyData(value: boolean): void {
        this._loadPointEnergyData = value;
    }

    @Mutation
    setCurrentPointEnergy(currentPointEnergy: MeterDeviceEnergy[]): void {
        this._currentPointEnergySet.energies = currentPointEnergy;
    }

    @Mutation
    setCurrentPointEnergySet(currentPointEnergySet: MeterDeviceEnergySet): void {
        this._currentPointEnergySet = currentPointEnergySet;
    }

    @Mutation
    private setPage(value: number | undefined): void {
        this._page = value;
    }

    @Mutation
    private setNothingLoading(value: boolean): void {
        this._nothingLoading = value;
    }

    @Action({ rawError: true })
    async updateCurrentPointEnergyData(): Promise<void> {
        try {
            this.setLoadingPointEnergyData(true);
            const proxy = await ProxyFactory.createMeterDeviceDataPresentationProxy();

            if (imsModule.currentPoint && dayjs.isDayjs(imsModule.beginCheckDate) && dayjs.isDayjs(imsModule.endCheckDate)) {
                const pointIdAsGuid = Guid.parse(imsModule.currentPoint);

                this.setPage(1);

                const meterEnergy = await proxy.getMeterDeviceEnergy(pointIdAsGuid, imsModule.energyType, imsModule.energyDirection,
                    imsModule.beginCheckDate.toDate(), imsModule.endCheckDate.toDate(), this.size, this.page);

                this.setCurrentPointEnergySet(meterEnergy);

                if (this.size) {
                    this.setNothingLoading(meterEnergy.energies.length < this.size);
                }
            }
        }
        catch (exc) {
            Logger.logError("updateCurrentPointEnergyData FAILED!", exc)
            throw exc;
        }
        finally {
            this.setLoadingPointEnergyData(false);
        }
    }

    @Action({ rawError: true })
    async loadMoreCurrentPointEnergyData(): Promise<void> {
        if (!this.size || !this.page || this._nothingLoading || this.loadingPointEnergyData) {
            return;
        }

        if (this.currentPointEnergy && this.currentPointEnergy.length < this.size) {
            return;
        }

        try {
            this.setLoadingPointEnergyData(true);
            const proxy = await ProxyFactory.createMeterDeviceDataPresentationProxy();

            if (imsModule.currentPoint && dayjs.isDayjs(imsModule.beginCheckDate) && dayjs.isDayjs(imsModule.endCheckDate)) {
                const pointIdAsGuid = Guid.parse(imsModule.currentPoint);

                imsEnergyModule.setPage(this.page + 1);

                const meterEnergy = await proxy.getMeterDeviceEnergy(pointIdAsGuid, imsModule.energyType, imsModule.energyDirection,
                    imsModule.beginCheckDate.toDate(), imsModule.endCheckDate.toDate(), this.size, this.page);

                if (meterEnergy.energies.length < this.size) {
                    this.setNothingLoading(true);
                }

                if (this.page > 1) {
                    const energy = this.currentPointEnergy.concat(meterEnergy.energies);
                    this.setCurrentPointEnergy(energy);
                } else {
                    this.setCurrentPointEnergy(meterEnergy.energies);
                }
            }
        }
        catch (exc) {
            Logger.logError("updateCurrentPointEnergyData FAILED!", exc)
            throw exc;
        } finally {
            this.setLoadingPointEnergyData(false);
        }
    }
}

export const imsEnergyModule = getModule(ImsEnergyModule);