import { ProxyBase } from "./common"
import { Appeal, Contract, Message, Topic } from "@/apiProxies/dataContracts/appeals"

export class AppealsPresenterProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "AppealsPresenter/", token, tokenType)
    }

    public getContract(number: string): Promise<Contract> {
        const action = "GetContract"

        return this.getDataFromAction<Contract>(action, {
            number
        })
    }

    public getTopics(contract: string): Promise<Topic[]> {
        const action = "GetTopics"

        return this.getDataFromAction(action, {
            topicType: contract
        })
    }

    public getAppeal(appealId: string): Promise<Appeal> {
        const action = "GetAppeal"

        return this.getDataFromAction(action, {
            appealId
        })
    }

    public getAppeals(): Promise<Appeal[]> {
        const action = "GetAppeals"

        return this.getDataFromAction(action)
    }

    public getMessages(appealId: string): Promise<Message[]> {
        const action = "GetMessages"

        return this.getDataFromAction(action, {
            appealId
        })
    }

    public downloadFile(appealId: string, messageId: string, fileId: number): Promise<Blob> {
        const action = "DownloadFile"

        return this.downloadData(action, {
            appealId,
            messageId,
            fileId: fileId.toString(),
        })
    }
}