import { Guid } from 'guid-typescript';
import { store } from "../index"
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ProxyFactory from '@/apiProxies/proxyFactory';
import { imsModule } from './ims';
import { Logger } from '@/common/logger';
import { preserveModule } from '../storeObject';

const moduleName = 'pointsFilter'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, preserveState: preserveModule(moduleName) })
export class PointsFilters extends VuexModule {

    private _searchName: string | undefined;
    private _buildingId: Guid | undefined;
    private _contractId: Guid | undefined;
    private _filtersVisible = false;
    private _pointsLoading = false;

    get filtersVisible(): boolean {
        return this._filtersVisible;
    }

    get pointsLoading(): boolean {
        return this._pointsLoading;
    }

    get buildingId(): Guid | undefined {
        return this._buildingId;
    }

    get contractId(): Guid | undefined {
        return this._contractId;
    }

    get searchName(): string | undefined {
        return this._searchName;
    }

    @Mutation
    setFiltersVisible(value: boolean): void {
        this._filtersVisible = value;
    }

    @Mutation
    setPointsLoading(value: boolean): void {
        this._pointsLoading = value;
    }

    @Mutation
    setBuildingId(value: Guid | undefined): void {
        this._buildingId = value;
    }

    @Mutation
    setContractId(value: Guid | undefined): void {
        this._contractId = value;
    }

    @Mutation
    setSearchName(value: string | undefined): void {
        this._searchName = value;
    }

    @Action
    async loadPointsWithFilters(): Promise<void> {
        this.setPointsLoading(true);
        try {
            const proxy = await ProxyFactory.creatPointsPresentationProxy();
            const points = await proxy.getPointsWithFilters(this.searchName, this.contractId, this.buildingId);

            imsModule.setPoints(points);

            if (points.length > 0) {

                let pointContains = false;

                if (imsModule.currentPoint) {
                    const currentPointId = Guid.parse(imsModule.currentPoint);
                    pointContains = points.find(x => x.data.id == currentPointId) !== undefined;
                }

                if (!pointContains) {
                    imsModule.setCurrentPoint(points[0].data.id)
                    await imsModule.updateCurrentPointData(points[0]);
                }
            } else {
                imsModule.unsetCurrentPoint();
            }
        } catch (error) {
            Logger.logError('loadPointsWithFilters FAILED', error);
        } finally {
            this.setPointsLoading(false);
        }
    }
}

export const pointsFilter = getModule(PointsFilters);