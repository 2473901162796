import { ProxyBase } from "./common"

export class AppealsManagerProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "AppealsManager/", token, tokenType)
    }

    public create(contractId: string, topicId: string): Promise<string> {
        const action = "Create"

        return this.postDataFromAction<string>(action, {
            contractId,
            topicId
        })
    }

    public delete(appealId: string): Promise<void> {
        const action = "Delete"

        return this.deleteDataFromAction(action, {
            appealId
        })
    }

    public createMessage(appealId: string, content: string, files: File[]): Promise<void> {
        const action = "CreateMessage"

        const formData = new FormData()

        formData.append('appealId', appealId)
        formData.append('content', content)
        files.forEach(file => {
            formData.append('files', file)
        })

        return this.postDataFromActionWithoutResult(action, formData)
    }
}