export abstract class ProxyBase {
    protected baseUrl: URL;
    protected token: string

    constructor(baseUrl: string, controllerUrl: string, token: string, tokenType: string) {
        this.baseUrl = new URL(controllerUrl, baseUrl);
        this.token = `${tokenType} ${token}`;
    }

    protected async getDataFromAction<T>(action: string, params?: string[][] | Record<string, string> | string | URLSearchParams): Promise<T> {
        const url = new URL(action, this.baseUrl);
        return await this.getData(url, params);
    }

    private async getData<T>(url: URL, params?: string[][] | Record<string, string> | string | URLSearchParams): Promise<T> {
        url.search = new URLSearchParams(params).toString();

        const headers = this.getRequestHeaders();

        const response = await fetch(url.toString(), {
            method: "GET",
            credentials: "include",
            headers: headers
        });

        if (response.ok) {
            try {
                const result = await response.json();
                return result as T;
            } catch (error) {
                if (error instanceof Error && error.name === 'SyntaxError') {
                    return {} as T
                }
            }
        }

        const errorReason = await response.text();
        throw new Error(errorReason);
    }

    protected async postDataWithSignal<T>(action: string, data = {}, signal: AbortSignal): Promise<T> {
        const url = new URL(action, this.baseUrl);

        const init: RequestInit = {
            method: "POST",
            credentials: "include",
            headers: {
                'Authorization': this.token
            },
            signal: signal
        };

        if (data instanceof FormData) {
            init.body = data;
        } else {
            init.headers = {
                ...init.headers,
                'Content-Type': 'application/json'
            }
            init.body = JSON.stringify(data);
        }

        const response = await fetch(url.toString(), init);

        if (response.ok) {
            const result = await response.json()
            return result as T
        }

        const errorReason = await response.text();
        throw new Error(errorReason);
    }

    protected async postDataFromAction<T>(action: string, data = {}): Promise<T> {
        const url = new URL(action, this.baseUrl);
        return await this.postData(url, data);
    }

    protected async postDataFromActionWithoutResult(action: string, data = {}): Promise<void> {
        const url = new URL(action, this.baseUrl);
        await this.postData(url, data, false);
    }

    private async postData<T>(url: URL, data = {}, parseResultBody = true): Promise<T> {
        const init: RequestInit = {
            method: "POST",
            credentials: "include",
            headers: {
                'Authorization': this.token
            }
        };

        if (data instanceof FormData) {
            init.body = data;
        } else {
            init.headers = {
                ...init.headers,
                'Content-Type': 'application/json'
            }
            init.body = JSON.stringify(data);
        }

        const response = await fetch(url.toString(), init);

        if (response.ok) {
            if (!parseResultBody) {
                return {} as T;
            }
            const result = await response.json();
            return result as T;
        }

        const errorReason = await response.text();
        throw new Error(errorReason);
    }

    protected async deleteDataFromAction(action: string, params?: string[][] | Record<string, string> | string | URLSearchParams): Promise<void> {
        const url = new URL(action, this.baseUrl)

        url.search = new URLSearchParams(params).toString()

        const response = await fetch(url.toString(), {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                'Authorization': this.token
            }
        })

        if (!response.ok) {
            const error = await response.text()
            throw new Error(error)
        }
    }

    protected async downloadData(action: string, params?: string[][] | Record<string, string> | string | URLSearchParams): Promise<Blob> {
        const headers = this.getRequestHeaders();

        const url = new URL(action, this.baseUrl);
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url.toString(), {
            method: "GET",
            credentials: "include",
            headers: headers
        })

        if (response.ok) {
            return response.blob()
        }

        throw new Error(await response.text());
    }

    private getRequestHeaders() {
        const headers = new Headers();
        headers.append('Authorization', this.token);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Credentials', 'true');
        headers.append('Access-Control-Allow-Headers', 'Content-Type, Authorization');
        return headers;
    }
}