import { ProxyBase } from "./common"
import { PaymentMethod, PaymentOrderData, SberResponseData, SbpResponseData } from "./dataContracts/personalAccount"

export class PaymentProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "Payment/", token, tokenType)
    }

    public getOrderInformation(orderId: number): Promise<PaymentOrderData> {
        return this.getDataFromAction<PaymentOrderData>("GetOrderInformation", {
            orderId: orderId.toString()
        })
    }

    public pay(contractId: string, electricityDebt: string, penaltiesDebt: string, taxDebt: string, successUrl: string, failUrl: string, paymentMethod: PaymentMethod, signal: AbortSignal): Promise<SberResponseData | SbpResponseData> {
        return this.postDataWithSignal<SberResponseData | SbpResponseData>("Pay", {
            electricityDebt,
            penaltiesDebt,
            taxDebt,
            successUrl,
            failUrl,
            contractId,
            paymentMethod
        }, signal)
    }
}