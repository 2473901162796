import { FileDescription } from './../../apiProxies/dataContracts';
import { store } from "../index"
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ProxyFactory from "@/apiProxies/proxyFactory";
import { LegalPersonContractData, ManagementObjectState, NewLegalPersonContractOrderData, NewPrivatePersonContractInitialData, Point, PrivatePersonContractData, UserContractDataBase } from "@/apiProxies/dataContracts";
import { Logger } from "@/common/logger";
import { Guid } from "guid-typescript";
import { preserveModule } from '../storeObject';

const moduleName = 'managementObjectsModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, stateFactory: true, preserveState: preserveModule(moduleName) })
export class ManagementObjectsModule extends VuexModule {

    points: Point[] = [];
    privatePersonContracts: PrivatePersonContractData[] = [];
    legalPersonContracts: LegalPersonContractData[] = [];
    private _contractsForFilters: UserContractDataBase[] = [];
    private _legalContractFilesInfo: FileDescription[] = [];
    private _privateContractInitialData: NewPrivatePersonContractInitialData | undefined

    //new private person contract dialog
    newPrivatePersonContractDialogVisible = false;
    newLegalPersonContractDialogVisible = false;

    public get existsManagementObjects(): boolean {
        return (this.points && this.points.length > 0) //хотябы 1 точка
            || (this.privatePersonContracts && this.privatePersonContracts.length > 0) //или 1 договор с фл
            || (this.legalPersonContracts && this.legalPersonContracts.length > 0); //или 1 договор с фл
    }

    public get existsApprovedManagementObjects(): boolean {
        const approvedPoints = this.points.filter(p => p.state == ManagementObjectState.approved);
        const approvedPrivatePersonContracts = this.privatePersonContracts.filter(p => p.state == ManagementObjectState.approved);
        const approvedLegalPersonContracts = this.legalPersonContracts.filter(p => p.state == ManagementObjectState.approved);

        return approvedPoints.length > 0 //хотябы 1 точка
            || approvedPrivatePersonContracts.length > 0 //или 1 договор с фл
            || approvedLegalPersonContracts.length > 0; //или 1 договор с фл
    }

    public get approvedPrivatePersonContracts(): PrivatePersonContractData[] {
        return this.privatePersonContracts.filter(p => p.state == ManagementObjectState.approved)
    }

    public get approvedLegalPersonContracts(): LegalPersonContractData[] {
        return this.legalPersonContracts.filter(p => p.state == ManagementObjectState.approved)
    }

    public get contractsForFilters(): UserContractDataBase[] {
        return this._contractsForFilters;
    }

    public get privateContractInitialData(): NewPrivatePersonContractInitialData | undefined {
        return this._privateContractInitialData;
    }

    public get legalContractFilesInfo(): FileDescription[] {
        return this._legalContractFilesInfo;
    }

    @Mutation
    public setPoints(points: Point[]): void {
        Logger.logInfo("Set points", points, this);
        this.points = points;
    }

    @Action({ rawError: true })
    public async loadPoints(): Promise<void> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();
        const points = await proxy.getPoints();
        Logger.logInfo("Load points", points, this);
        this.setPoints(points);
    }

    @Mutation
    public setPrivatePersonContracts(contracts: PrivatePersonContractData[]): void {
        this.privatePersonContracts = contracts;
    }

    @Mutation
    public setLegalPersonContracts(contracts: LegalPersonContractData[]): void {
        this.legalPersonContracts = contracts;
    }

    @Mutation
    public setContractsForFilters(contracts: UserContractDataBase[]): void {
        this._contractsForFilters = contracts;
    }

    @Mutation
    public setPrivateContractInitialData(data: NewPrivatePersonContractInitialData): void {
        this._privateContractInitialData = data
    }

    @Mutation
    public setLegalContractFilesInfo(data: FileDescription[]): void {
        this._legalContractFilesInfo = data || []
    }

    @Action({ rawError: true })
    public async addLegalPersonContractOrder(contractOrder: NewLegalPersonContractOrderData): Promise<void> {
        const proxy = await ProxyFactory.createRegistrationManagerProxy();
        try {
            await proxy.addLegalPersonContractOrder(contractOrder);
        } catch (error) {
            Logger.logError("addLegalPersonContractOrder FAILED!", error)
            throw error;
        }
    }

    @Action({ rawError: true })
    public async validateLegalPersonContractOrder(contractOrder: NewLegalPersonContractOrderData): Promise<void> {
        const proxy = await ProxyFactory.createRegistrationManagerProxy();
        try {
            await proxy.validateLegalPersonContractOrder(contractOrder);
        } catch (error) {
            Logger.logError("validateLegalPersonContractOrder FAILED!", error)
            throw error;
        }
    }

    @Action
    public async getPrivatePersonContractInitialData(): Promise<void> {
        try {
            const proxy = await ProxyFactory.createManagementObjectsPresentationProxy()

            const data = await proxy.getPrivatePersonContractInitialData()
            this.setPrivateContractInitialData(data)
        } catch (error) {
            Logger.logError("getPrivatePersonContractDates FAILED!", error)
            throw error;
        }
    }

    //@Action({ rawError : true }) - для примера как показывать текст ошибки
    @Action
    public async loadPrivatePersonContracts(): Promise<void> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();
        const contracts = await proxy.getPrivatePersonContracts();
        this.setPrivatePersonContracts(contracts);
    }

    @Action
    public async removePrivatePersonContract(contractId: Guid): Promise<void> {
        const proxy = await ProxyFactory.createRegistrationManagerProxy();

        await proxy.removePrivatePersonContract(contractId.toString());

        const index = this.privatePersonContracts.findIndex(x => x.id === contractId);
        if (index > -1) {
            this.privatePersonContracts.splice(index, 1);
        }
    }

    @Action({ rawError: true })
    public async loadLegalPersonContractFilesInfo(orderId: string): Promise<void> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();
        const filesInfo = await proxy.getLegalPersonContractFilesInfo(orderId);

        this.setLegalContractFilesInfo(filesInfo);
    }

    @Action({ rawError: true })
    public async loadLegalPersonContracts(): Promise<void> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();
        const contracts = await proxy.getLegalPersonContracts();
        if (contracts) {
            Logger.logInfo("Load contracts", contracts, this);
            this.setLegalPersonContracts(contracts);
        }
    }

    @Action
    public async removeLegalPersonContract(contractId: Guid): Promise<void> {
        const proxy = await ProxyFactory.createRegistrationManagerProxy();

        await proxy.removeLegalPersonContract(contractId.toString());

        const index = this.legalPersonContracts.findIndex(x => x.id === contractId);
        if (index > -1) {
            this.legalPersonContracts.splice(index, 1);
        }
    }

    @Action({ rawError: true })
    public async loadContractsForFilters(buildingId?: Guid): Promise<void> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();
        try {
            const contracts = await proxy.getContractsForFilters(buildingId);
            this.setContractsForFilters(contracts);
        } catch (error) {
            Logger.logError("loadContractsForFilters FAILED!", error)
            throw error;
        }
    }

    @Action
    public async getLegalContractFile(file: { orderId: string, fileId: number }): Promise<Blob> {
        const proxy = await ProxyFactory.createManagementObjectsPresentationProxy();

        return await proxy.getLegalPersonContractFile(file.orderId, file.fileId);
    }

    @Mutation
    public setNewPrivatePersonContractDialogVisible(value: boolean): void {
        this.newPrivatePersonContractDialogVisible = value;
    }

    @Mutation
    public setNewLegalPersonContractDialogVisible(value: boolean): void {
        this.newLegalPersonContractDialogVisible = value;
    }
}

export const managementObjectsModule = getModule(ManagementObjectsModule);