import { ProxyBase } from "./common";
import { getFormattedBeginDate, getFormattedEndDate } from "@/common/dateHelper";
import { EventTypeData, MeterDeviceEvent, MeterDeviceEventLogData } from "./dataContracts";

export class MeterDeviceEventLogPresentationProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "ImsMeterDeviceEventLogPresenter/", token, tokenType)
    }

    public async getMeterDeviceEventTypes(): Promise<EventTypeData[]> {
        return await this.getDataFromAction<EventTypeData[]>("GetMeterDeviceEventTypes");
    }

    public async getMeterDeviceEvents(eventType: EventTypeData): Promise<MeterDeviceEvent[]> {
        return await this.getDataFromAction<MeterDeviceEvent[]>("GetMeterDeviceEvents", {
            eventType: eventType.id.toString()
        });
    }

    public async getMeterDeviceEventLog(pointId: string, events: string[] | null, beginDate: Date, endDate: Date): Promise<MeterDeviceEventLogData[]> {
        return await this.postDataFromAction<MeterDeviceEventLogData[]>("GetMeterDeviceEventLog", {
            events: events,
            pointId: pointId,
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        });
    }
}