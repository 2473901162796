import { getFormattedBeginDate, getFormattedEndDate } from "@/common/dateHelper"
import { ProxyBase } from "./common"
import { FileDescription } from "./dataContracts"
import { PaymentDocument, PaymentDocumentFileType } from "./dataContracts/personalAccount"

export class PaymentDocumentsPresenterProxy extends ProxyBase {

    constructor(baseUrl: string, token: string, tokenType: string) {
        super(baseUrl, "PaymentDocumentPresenter/", token, tokenType)
    }

    public getPaymentDocuments(contractId: string, beginDate: Date, endDate: Date): Promise<PaymentDocument[]> {
        return this.getDataFromAction<PaymentDocument[]>("GetPaymentDocuments", {
            contractId,
            beginDate: getFormattedBeginDate(beginDate),
            endDate: getFormattedEndDate(endDate)
        })
    }

    public getPaymentDocumentFileInfo(contractId: string, period: string, fileType: PaymentDocumentFileType): Promise<FileDescription> {
        return this.getDataFromAction<FileDescription>("GetPaymentDocumentFileInfo", {
            contractId,
            period,
            fileType: fileType.toString()
        })
    }

    public downloadPaymentDocumentFile(contractId: string, period: string, fileType: PaymentDocumentFileType): Promise<Blob> {
        return this.downloadData("DownloadPaymentDocumentFile", {
            contractId,
            period,
            fileType: fileType.toString()
        })
    }
}