import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { store } from "../index"
import { preserveModule } from "../storeObject"

const moduleName = 'appealsCache'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, stateFactory: true, preserveState: preserveModule(moduleName) })
export class AppealsCacheModule extends VuexModule {
    private _appealsDrafts: [string, string][] = []

    public get appealsDrafts(): Map<string, string> {
        return new Map(this._appealsDrafts)
    }

    @Mutation
    setDrafts(value: [string, string][]): void {
        this._appealsDrafts = value
    }
}

export const appealsCacheModule = getModule(AppealsCacheModule)