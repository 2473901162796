import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { PrivatePersonContractData, LegalPersonContractData } from '../../apiProxies/dataContracts'

import { store } from "../index"
import { preserveModule } from "../storeObject"

const moduleName = 'personalAccountCacheModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true, stateFactory: true, preserveState: preserveModule(moduleName) })
export class PersonalAccountCacheModule extends VuexModule {
    private _selectedContract: PrivatePersonContractData | LegalPersonContractData | undefined

    public get selectedContract(): PrivatePersonContractData | LegalPersonContractData | undefined {
        return this._selectedContract
    }

    @Mutation
    setSelectedContract(value: PrivatePersonContractData | LegalPersonContractData | undefined): void {
        this._selectedContract = value
    }
}

export const personalAccountCache = getModule(PersonalAccountCacheModule)