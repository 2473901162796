import { Dayjs } from 'dayjs';
import { Guid } from "guid-typescript";

export type PeriodicData<T> = {
    beginDate: Date;
    endDate: Date | null;
    data: T;
}

//Partners
export enum PartnerType {
    privatePerson = 0,
    simpleLegalPerson = 1,
    soleTrader = 2,
    privatePersonWithBusiness = 4
}

export type PartnerData = {
    id: Guid,
    type: PartnerType,
    fullName: string,
    Name: string,
    unifiedStateRegisterNumber: string,
    unifiedStateRegisterDate?: Date,
    isDeleted: boolean
}

export enum ManagementObjectState {
    new = 0,
    processing = 1,
    approved = 2,
    rejected = 3
}

//Contracts
export type UserContractDataBase = {
    id: Guid,
    orderId?: string,
    number: string,
    beginDate: Date
    endDate?: Date
    partnerId: Guid,
    pointName: string,
    state: ManagementObjectState,
    type: ContractType
}

export enum ContractType {
    private,
    legal
}

export type PrivatePersonContractData = UserContractDataBase & {
    address: string,
}

export type LegalPersonContractData = UserContractDataBase & {
    partnerName: string,
}

export type FileDescription = {
    id: number,
    name: string,
    extension: string,
    created: Date
}

export type NewPrivatePersonContractInitialData = {
    startDateCheckedPaymentDocument: Date,
    endDateCheckedPaymentDocument: Date
}

//Points
export type PointContract = {
    contractNumber: string,
    contractsDate: Date | null,
    partnerName: string
}

export type Point = {
    id: Guid;
    meterLocationGlobalId: string,
    pointGlobalId: string,
    name: string;
    address: string,
    buildingId: Guid,
    imsEntryDate: Date,
    state: ManagementObjectState
}

export type Building = {
    id: Guid,
    name: string,
    address: string
}

export type BuildingsFilterData = {
    searchName: string,
    contractId?: Guid
}

export type MeterDeviceInfo = {
    id: Guid;
    type: string;
    serialNumber: string;
    validPeriod: number;
    registrationNumber: string;
    accuracyClass: string;
    ratedVoltage: string;
    maxCurrent: string;
    baseCurrent: string;
    isConnected: boolean | null;
    installationPlace: string;
    verificationDate: Date | null;
    nextVerificationDate: Date | null;
    instrumentalCheckDate: Date | null;
    lastDataReadingDate: Date | null;
}

export enum TransformerKind {
    CurrentTransformer = 0,
    VoltageTransformer = 1
}

export type TransformerDevice = {
    id: Guid;
    type: string;
    transformerKind: TransformerKind;
    serialNumber: string;
    registrationNumber: string;
    validationPeriod: number;
    accuracyClass: string;
    initialValue: number;
    secondaryValue: number;
    installationPlace: string;
    instrumentalCheckDate: Date | null;
    lastVerificationDate: Date | null;
    nextVerificationDate: Date | null;
}

export type PointImsUser = {
    partnerName: string,
    partnerType: PartnerType,
    unifiedStateRegisterNumber: string,
    unifiedStateRegisterDate: Date | null,
    contractNumber: string,
    installationAddress: string
}

export enum PartnerType {
    abonent = 0,
    company = 1,
    individual = 2,
    person = 4,
}

export type PointCommonProperties = {
    point: Point;
    meterDevice: MeterDeviceInfo;
    transformerDevices: TransformerDevice[];
    contracts: PointContract[]
    imsUsers: PointImsUser[]
}

//registration
export enum RegistrationObjectType {
    contractWithPrivate = 0,
    contractWithLegal = 1,
    meterPoint = 2
}

export type NewPrivatePersonContractOrderData = {
    contractNumber: string,
    validationCode: string
}

export type NewLegalPersonContractOrderData = {
    contractNumber: string,
    partnerInn: string,
    files: File[]
}

//indication
export type MeterDeviceIndicationSet = {
    meterDevice: MeterDeviceInfo;
    tariffZones: MeterDeviceTariffZone[];
    indications: MeterDeviceIndication[];
}

export type MeterDeviceIndication = {
    meterDeviceId: Guid;
    period: Date;
    energyT1: number;
    energyT2: number;
    energyT3: number;
    energyT4: number;
}

//power
export type MeterDevicePowerSet = {
    powers: MeterDevicePower[];
}

export type MeterDevicePower = {
    meterDeviceId: Guid;
    beginDate: Date | null;
    endDate: Date;
    totalPowerMin: number;
    totalPowerMax: number;
    activePowerMin: number;
    activePowerMax: number;
    reactivePowerMin: number;
    reactivePowerMax: number;
}

//energy
export type MeterDeviceEnergySet = {
    energies: MeterDeviceEnergy[];
    totalEnergy: number;
}

export type MeterDeviceEnergy = {
    meterDeviceId: Guid;
    beginDate: Date | null;
    endDate: Date;
    energy: number;
}

export type MeterDeviceEnergyForPower = MeterDeviceEnergy & {
    isMarked: boolean;
}

export type MeterDeviceEnergyForPowerSet = {
    energies: MeterDeviceEnergyForPower[];
    averageEnergyValue: number;
}

export enum EnergyType {
    Active,
    Reactive
}

export enum EnergyDirection {
    Export,
    Import
}

export type MeterDeviceTariffZone = {
    meterDeviceId: Guid;
    scaleNumber: number;

    tariffZone: TariffZone;
    beginDate: Date;
    endDate: Date | null;
}

export enum TariffZone {
    FullDay = 0,
    Day = 1,
    Night = 2,
    Peak = 3,
    HalfPeak = 4
}

export const getTariffZoneName = (zone: TariffZone): string => {
    switch (zone) {
        case TariffZone.FullDay: return 'Сутки'
        case TariffZone.Day: return 'День'
        case TariffZone.Night: return 'Ночь'
        case TariffZone.Peak: return 'Пик'
        case TariffZone.HalfPeak: return 'Полупик'
        default: throw new Error()
    }
}

//event log
export type EventTypeData = {
    id: number,
    name: string
}

export type MeterDeviceEvent = {
    id: Guid
    name: string,
    eventType: number
}

export type MeterDeviceEventLogData = {
    period: Date,
    eventName: string
}

//ims
export type PeriodDependent = {
    beginDate: Dayjs,
    endDate: Dayjs
}

export type EnergyDependent = {
    typeEnergy?: boolean,
    directionEnergy?: boolean
}

//tech connection
export type TechConnection = {
    id: string,
    contractId: string,
    number: string,
    onPaper: boolean,
    objectName: string,
    date: string,
    state: TechConnectionState,
    stateDescription: string,
    type: ContractType
}


export type TechConnectionDetails = {
    data: TechConnection,
    files: TechConnectionFile[],
    stateDescription: string
}

export type TechConnectionFile = {
    id: string,
    name: string,
    date: string,
    owner: TechConnectionFileOwner,
    fileType: TechConnectionFileType,
    fileState: TechConnectionFileState
}

export enum TechConnectionState {
    pendingGpAction,
    pendingUserAction,
    pendingSendToGp,
    pendingSendToUser,
    ended,
    rejected
}

export enum TechConnectionFileType {
    contract,
    disagreement,
    resolution,
    technicalConnectionAct,
    connectionPossibilityNotification,
    certificateMeterDevice,
    contractSign,
    disagreementSign,
    resolutionSign,
}

export enum TechConnectionFileState {
    waitingGpSigning,
    signedGp,
    sentToUser,
    waitingUserSigning,
    signedUser,
    sentForSignatureVerification,
    signatureError,
    blocked,
    signed
}

export enum TechConnectionFileOwner {
    other,
    user
}